

























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { ClienteService } from '@/core/services/geral';
import { Equipamento } from '@/core/models/residuo';
import { EquipamentoService } from '@/core/services/residuo';

@Component
export default class ConfiguracaoRelatorioLocacao extends PageBase {

    clienteService: ClienteService = new ClienteService();
    onSearchCliente: any = null;
    clientes: any[] = [];
    embarcacoes: any[] = [];

    ordenacoes: any[] = [
        { id: 1, nome: "#OS" },
        { id: 2, nome: "Cliente" },
        { id: 3, nome: "Embarcação" },
        { id: 4, nome: "Data Inicio" },
        { id: 5, nome: "Data Fim" },
        { id: 6, nome: "Equipamento" },
        { id: 7, nome: "Equipamento(Troca)" }
    ];
    ordens: any[] = [];

    equipamentoService: EquipamentoService = new EquipamentoService();
    onSearchEquipamento: any = null;
    equipamentos: Equipamento[] = [];
    onSearchEquipamentoTroca: any = null;
    equipamentoTrocas: Equipamento[] = [];

    filtro: any = {
        numOS: null,
        clienteId: null,
        embarcacaoId: null,
        equipamentoId: null,
        equipamentoTrocaId: null,
        dataInicio: null,
        dataFim: null,
        ordenacao: null
    }

    @Watch('onSearchCliente')
    WatchSearchCliente(val: string){
        if(val.length > 0){
            this.clienteService.AutoComplete(val).then(
                res => {
                    this.clientes = res.data;
                }
            )
        }
        else
            return;
    }

    @Watch('onSearchEquipamento')
    WatchEquipamento(val: string){
        if(val.length > 0){
            this.equipamentoService.AutoComplete(val, false).then(
                res => {
                    this.equipamentos = res.data;
                }
            )
        }
        else
            return;
    }

    @Watch('onSearchEquipamentoTroca')
    WatchEquipamentoTroca(val: string){
        if(val.length > 0){
            this.equipamentoService.AutoComplete(val, false).then(
                res => {
                    this.equipamentoTrocas = res.data;
                }
            )
        }
        else
            return;
    }

    @Watch('filtro.clienteId')
    WatchCliente(){
        if(this.filtro.clienteId > 0){
            this.clienteService.ObterPorId(this.filtro.clienteId, "Embarcacoes").then(
                res => {
                    this.embarcacoes = res.data.embarcacoes
                }
            )
        }
        else
            return;
    }

    PreparaOrdenacao(){
        this.filtro.ordenacao = '';
        this.ordens.forEach(x => {
            this.filtro.ordenacao += `${x},`;
        });
        console.log(this.filtro.ordenacao)
    }

    GerarRelatorio(){
        
        let routeLink = this.$router.resolve({name: "detalharRelatorioLocacao",
            query:{
                numOS: this.filtro.numOS,
                clienteId: this.filtro.clienteId,
                embarcacaoId: this.filtro.embarcacaoId,                
                equipamentoId: this.filtro.equipamentoId,
                equipamentoTrocaId: this.filtro.equipamentoTrocaId,
                dataInicio: this.filtro.dataInicio,
                dataFim: this.filtro.dataFim,
                ordenacao: this.filtro.ordenacao
            }
        });
        window.open(routeLink.href, '_blank');
    }

    LimparFiltros(){
        this.clientes = [];
        this.embarcacoes = [];
        this.onSearchCliente = null;
        this.filtro = {
            numOS: null,
            clienteId: null,
            embarcacaoId: null,
            equipamentoId: null,
            equipamentoTrocaId: null,
            dataInicio: null,
            dataFim: null,
            ordenacao: null
        }
    }
}  

